import React from 'react';

import FacebookImg from "../../../../assets/icon-social/facebook.png";
import GmailImg from "../../../../assets/icon-social/gmail.png";
import InstagramImg from "../../../../assets/icon-social/instagram.png";
import WppImg from "../../../../assets/icon-social/wpp.png";

function ChannelIcon({ channel }) {
  switch (channel) {
    case 'email':
      return <img src={GmailImg} alt="ícone do Gmail" width={26} height={26} />;
    case 'instagram':
      return <img src={InstagramImg} alt="ícone do Instagram" width={26} height={26} />;
    case 'facebook':
      return <img src={FacebookImg} alt="ícone do Facebook" width={26} height={26} />;
    default:
      return <img src={WppImg} alt="ícone do WhatsApp" width={26} height={26} />;
  }
}

export default ChannelIcon;
