import React, { createContext, useCallback, useContext, useEffect } from "react";

import api from '../../services/api';
import { useAuthContext } from '../Auth';
import { useSettingsContext } from '../Settings/index.jsx';

const OnlineContext = createContext();

export function useOnlineContext() {
  return useContext(OnlineContext)
}

export default function OnlineProvider({ children }) {
	const { getSettingValue } = useSettingsContext();
	const { user } = useAuthContext();

  const goOffline = useCallback(async () => {
    if (!user?.id) return;
    await api.put(`/users/${user.id}`, { online: false });
  }, [user]);

  useEffect(() => {
    if (user.profile === "admin") return;

    const inactiveTimer = getSettingValue('inactiveTimer') || 5;
    const minutes = inactiveTimer * 60 * 1000;

    let timeout = setTimeout(goOffline, minutes);

    const resetOfflineTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(goOffline, minutes);
    };

    document.onmousemove = resetOfflineTimer;
    document.onkeydown = resetOfflineTimer;
    document.onclick = resetOfflineTimer;

    return () => {
			document.onmousemove = null;
			document.onkeydown = null;
      clearTimeout(timeout);
		}
  }, [getSettingValue, goOffline]);

	return (
		<OnlineContext.Provider>
			{children}
		</OnlineContext.Provider>
	);
};
