import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

function Loading(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} {...props}>
      <CircularProgress color="primary" />
    </div>
  )
}

export default Loading;
