import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";

import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import { useParams } from 'react-router-dom';

import { useAuthContext } from "../../context/Auth";
import { useTicketsContext } from "../../context/Tickets/TicketsContext";
import useTickets from "../../hooks/useTickets";
import { socketConnection } from "../../services/socket";
import { i18n } from "../../translate/i18n";
import TicketListItem from "../TicketListItemCustom";
import TicketsListSkeleton from "../TicketsListSkeleton";
import { useStyles } from './styles';

function orderAllTickets(tickets, order, pinnedTickets) {
  const map = new Map();

	if (!order) {
    const allTickets = [...pinnedTickets, ...tickets];
    allTickets.forEach((ticket) => map.set(ticket.id, ticket));
    return Array.from(map.values());
  }

	if (order === "CREATION_ASC") {
		tickets.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
	}

	if (order === "CREATION_DESC") {
		tickets.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
	}

	if (order === "UPDATE_ASC") {
		tickets.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
	}

	if (order === "UPDATE_DESC") {
		tickets.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
	}

  const allTickets = [...pinnedTickets, ...tickets];
  allTickets.forEach((ticket) => map.set(ticket.id, ticket));
  return Array.from(map.values());
}

const reducer = (state, action) => {
  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload;

    newTickets.forEach((ticket) => {
      const ticketIndex = state.findIndex((t) => t.id === ticket.id);
      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
        if (ticket.unreadMessages > 0) {
          state.unshift(state.splice(ticketIndex, 1)[0]);
        }
      } else {
        state.push(ticket);
      }
    });

    return [...state];
  }

  if (action.type === "RESET_UNREAD") {
    const ticketId = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].unreadMessages = 0;
      state[ticketIndex].markedUnreadMessages = false;
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
    const ticket = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticket.id);

    if (ticketIndex !== -1) {
      const previousTicket = state[ticketIndex];
      state[ticketIndex] = { ...previousTicket, ...ticket };
      state.unshift(state.splice(ticketIndex, 1)[0]);
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_CONTACT") {
    const contact = action.payload;
    const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].contact = contact;
    }
    return [...state];
  }

  if (action.type === "DELETE_TICKET") {
    const ticketId = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state.splice(ticketIndex, 1);
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

function TicketsList(props) {
  const {
    tab,
    status,
    isGroup,
    searchParam,
    tags,
    users,
    showAll,
    selectedQueues,
		selectedWhatsapp,
    updateCount,
    style,
		order,
		unreadMessages,
		choosePrefDate,
		initialDate,
		finalDate
  } = props;

  const classes = useStyles();
  const { ticketId } = useParams();
  const [ticketList, dispatch] = useReducer(reducer, []);
  const { user } = useAuthContext();
	const { updateTicketTemp } = useTicketsContext();

  const [pageNumber, setPageNumber] = useState(1);
  const [selectedTicketId, setSelectedTicketId] = useState(null);

  const queueIds = useMemo(() => user.queues.map((queue) => queue.id), [user]);
  const selectedQueueIds = useMemo(() => selectedQueues.map(q => q.id), [selectedQueues]);
  const tagIds = useMemo(() => tags.map(t => t.id), [tags]);
  const userIds = useMemo(() => users.map(u => u.id), [users]);
  const whatsappIds = useMemo(() => selectedWhatsapp.map(w => w.id), [selectedWhatsapp]);

  const { tickets, pinnedTickets, setPinnedTickets, hasMore, loading } = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    tags: JSON.stringify(tagIds),
    users: JSON.stringify(userIds),
    queueIds: JSON.stringify(selectedQueueIds),
		whatsappIds: JSON.stringify(whatsappIds),
		order,
		unreadMessages,
		choosePrefDate,
		initialDate,
		finalDate,
    tab,
  });

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const getSelectedTicket = (ticket) => {
    return ticketId === ticket.uuid;
  };

  const handleUnpin = (ticketId) => {
    const pinned = JSON.parse(localStorage.getItem('pinned'));

    if (Array.isArray(pinned)) {
      const newPinned = pinned.filter(tickId => tickId !== ticketId);
      localStorage.setItem('pinned', JSON.stringify(newPinned));

      return setPinnedTickets(prev => {
        const newValue = prev.filter(tick => tick.id !== ticketId);
        return newValue;
      });
    }
  }

  const filterIsGroup = useCallback(ticket => {
    if (tab === 'search') return true;

    return isGroup ? ticket.isGroup : !ticket.isGroup
  }, [isGroup, tab]);

  const isTicketInUserQueues = useCallback(ticket => {
    const queues = selectedQueueIds.length ? selectedQueueIds : queueIds;
    const isStatusClosed = status === 'closed' && ticket.status === 'closed';
    const isUserAdmin = user.profile === 'admin' && ticket.queueId === null;

    return isStatusClosed || isUserAdmin ? true : queues.includes(ticket.queueId);
  }, [queueIds, selectedQueueIds]);

  const isTicketOnFilter = useCallback(ticket => {
    let isOnTags = true;
    let isOnUsers = true;
    let isOnConnections = true;
    let isOnDate = true;

    if (tagIds.length) {
      isOnTags = ticket.tags?.some(tag => tagIds.includes(tag.id));
    }

    if (userIds.length) {
      isOnUsers = userIds.includes(ticket.userId);
    }

    if (whatsappIds.length) {
      isOnConnections = whatsappIds.includes(ticket.whatsappId);
    }

    if (choosePrefDate) {
      const beginDate = new Date(initialDate);
      const ultimateDate = new Date(finalDate);

      if (choosePrefDate === 'createdAt') {
        const createdAtDate = new Date(ticket.createdAt);
        isOnDate = createdAtDate >= beginDate && createdAtDate <= ultimateDate;
      }
      if (choosePrefDate === 'updatedAt') {
        const updatedAtDate = new Date(ticket.updatedAt);
        isOnDate = updatedAtDate >= beginDate && updatedAtDate <= ultimateDate;
      }
    }

    return isOnTags && isOnUsers && isOnConnections && isOnDate;
  }, [tagIds, userIds, whatsappIds, choosePrefDate, initialDate, finalDate]);

  const userCanSee = useCallback(ticket => {
    const isQueueRoutingDisable = !ticket.queue?.ativarRoteador;
    const isTicketPending = ticket.status === 'pending';

    if (ticket.status === 'closed') {
      return user.showClosedTickets;
    }

    if (user.showChat || (isQueueRoutingDisable && isTicketPending)) {
      return isTicketInUserQueues(ticket);
    }

    return isTicketInUserQueues(ticket) && ticket.userId === user.id;
  }, [user]);

  const filteredTickets = useMemo(() => tickets.filter(ticket => {
    return filterIsGroup(ticket) && userCanSee(ticket);
  }), [tickets, filterIsGroup, userCanSee]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [
			status,
			searchParam,
			dispatch,
			showAll,
			tags,
			users,
			selectedQueues,
			selectedWhatsapp,
			order,
			unreadMessages,
			choosePrefDate,
			initialDate,
			finalDate
		]
	);

  useEffect(() => {
		if (user.profile === "user") {
      dispatch({
        type: "LOAD_TICKETS",
        payload: filteredTickets,
      });
    } else {
      dispatch({ type: "LOAD_TICKETS", payload: tickets.filter(filterIsGroup) });
    }
  }, [tickets, user, filteredTickets, filterIsGroup]);

	useEffect(() => {
		if (updateTicketTemp?.status === status && status === "open") {
			dispatch({
				type: "UPDATE_TICKET",
				payload: updateTicketTemp
			});
		}
	}, [updateTicketTemp, status]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    const shouldUpdateTicket = (ticket) => {
			return (
        (!ticket.userId || ticket.userId === user?.id || (user.showChat && isTicketInUserQueues(ticket)) || showAll) &&
        (!ticket.queueId || isTicketInUserQueues(ticket)) && isTicketOnFilter(ticket)
      );
		}

    socket.on("connect", () => {
      if (status) {
        socket.emit("joinTickets", status);
      } else {
        socket.emit("joinNotification");
      }
    });

    socket.on(`company-${companyId}-ticket`, (data) => {
      if (data.action === "updateUnread") {
        dispatch({
          type: "RESET_UNREAD",
          payload: data.ticketId,
        });
      }

      if (
				data.action === "update" &&
				shouldUpdateTicket(data.ticket)
			) {
				if (!data.ticket.userId && user.profile === 'user' && !user.showChat) {
					return;
				}
        dispatch({
          type: "UPDATE_TICKET",
          payload: data.ticket,
        });
      }

      if (data.action === "update" && !isTicketInUserQueues(data.ticket)) {
        dispatch({ type: "DELETE_TICKET", payload: data.ticket?.id });
      }

      if (data.action === "delete") {
        if (status === 'open') {
          handleUnpin(data.ticketId);
        }

        dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
      }
    });

    socket.on(`company-${companyId}-appMessage`, (data) => {
      if (user.profile === "user" && !isTicketInUserQueues(data.ticket)) {
        return;
      }

      if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
				if (!data.ticket.userId && user.profile === 'user' && !user.showChat) {
					return;
				}
        dispatch({
          type: "UPDATE_TICKET_UNREAD_MESSAGES",
          payload: data.ticket,
        });
      }
    });

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data.action === "update") {
        dispatch({
          type: "UPDATE_TICKET_CONTACT",
          payload: data.contact,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [status, showAll, user, isTicketOnFilter, isTicketInUserQueues]);

  useEffect(() => {
    if (typeof updateCount === "function") {
      updateCount(ticketList.length);
    }
  }, [ticketList, updateCount]);

  return (
    <Paper className={classes.ticketListWrapper} style={style}>
      <Paper
        square
        name="closed"
        elevation={0}
        className={classes.ticketList}
        onScroll={handleScroll}
      >
        <List className={classes.ticketListBox}>
          {ticketList.length === 0 && !loading ? (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage")}
              </p>
            </div>
          ) : (
            <>
              {
                orderAllTickets(ticketList, order, pinnedTickets).map((ticket) => (
                  <TicketListItem
                    key={ticket.id}
                    ticket={ticket}
                    isPinnedTicket={pinnedTickets.find((tick) => tick.id === ticket.id)}
                    setPinnedTickets={setPinnedTickets}
                    setSelectedTicketId={setSelectedTicketId}
                    isSelected={getSelectedTicket(ticket)}
                    isTabOpen={tab === 'open'}
                  />
                ))
              }
            </>
          )}
          {loading && <TicketsListSkeleton />}
        </List>
      </Paper>
    </Paper>
  );
};

export default TicketsList;
