import React from 'react';

import { Stack, Typography } from '@mui/material';
import clsx from 'clsx';

import { checkBodyIsMediaUrl } from '../../helpers';
import QuotedMedia from '../QuotedMedia';
import ViewOnce from '../ViewOnce';
import { useStyles } from './styles';

function QuotedMessage({ quotedMsg, isFromMe }) {
  const classes = useStyles();

  const isMedia = quotedMsg?.mediaUrl;

  return (
    <a
      href={`#${quotedMsg.id}`}
      className={clsx(
        classes.quotedContainer,
        classes.quotedContainerLeft,
        { [classes.quotedContainerRight]: isFromMe },
      )}
    >
      <span
        className={clsx(
          classes.quotedSideColorLeft,
          { [classes.quotedSideColorRight]: quotedMsg.fromMe },
        )}
      />

      <div className={classes.quotedMsg}>
        <Typography className={classes.messageContactName}>
          {quotedMsg?.contact?.name}
        </Typography>
        <Stack>

          <QuotedMedia
            mediaType={quotedMsg.mediaType}
            mediaUrl={quotedMsg.mediaUrl}
            body={quotedMsg.body}
          />

          <Typography>
            {!checkBodyIsMediaUrl(quotedMsg) && quotedMsg?.body}

            {quotedMsg.mediaType === 'viewOnceMessageV2' && <ViewOnce disable />}
          </Typography>
        </Stack>
      </div>
    </a>
  );
}

export default QuotedMessage;
