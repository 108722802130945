import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Badge } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import SearchIcon from '@material-ui/icons/Search';
import { Checkbox, FormControlLabel, IconButton } from '@mui/material';
import clsx from 'clsx';
import { ArrowDownNarrowWide, BellRing, Filter, Inbox, MessageCirclePlus, Search, Users } from 'lucide-react';
import { useHistory } from 'react-router-dom';

import { useAuthContext } from '../../context/Auth';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import FilterModal from '../FilterModal';
import FunnerModal from '../FunnerModal';
import NewTicketModal from '../NewTicketModal';
import TabPanel from '../TabPanel';
import TicketsList from '../TicketsListCustom';
import { useStyles } from './styles';

function TicketsManagerTabs() {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuthContext();

  const [unreadMessages, setUnreadMessages] = useState(false);
  const [searchParam, setSearchParam] = useState('');
  const [searchClosed, setSearchClosed] = useState(false);
  const [tab, setTab] = useState('open');
  const [tabOpen, setTabOpen] = useState('open');
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [userWhatsapps, setUserWhatsapps] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState('open');
	const [choosePrefDate, setChoosePrefDate] = useState(null);
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [prefObject, setPrefObject] = useState({
    open: 'UPDATE_DESC',
    pending: 'UPDATE_DESC',
  });

  const getSavedFilters = useCallback(() => {
    const data = JSON.parse(localStorage.getItem(`${tab}:filters`));
    return data;
  }, [tab]);

  const filtersCount = useMemo(() => {
    let count = 0;

    if (selectedQueues.length > 0) count += 1;

    if (selectedWhatsapp.length > 0) count += 1;

    if (selectedTags.length > 0) count += 1;

    if (selectedUsers.length > 0) count += 1;

    if (choosePrefDate) count += 1;

    return count;
  }, [selectedQueues, selectedWhatsapp, selectedTags, selectedUsers, choosePrefDate]);

  const getWhatsappsByQueues = async () => {
    const { data } = await api.get('/whatsapp-by-queues', {
      params: {
        queueIds: JSON.stringify(user.queues?.map(q => q.id)),
      },
    });

    setUserWhatsapps(data);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setSelectedTab(newValue);
    setTabOpen(newValue);
  };

  const applyPanelStyle = status => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleCloseOrOpenTicket = ticket => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleFunner = useCallback(
    e => {
      setAnchorEl(e.currentTarget);
      setSelectedTab(selectedTab);
    },
    [selectedTab]
  );

  const handleSearch = e => {
    const searchedTerm = e.target.value.toLowerCase();

    setSearchParam(searchedTerm);
  };

  useEffect(() => {
    const data = getSavedFilters();
    setSelectedQueues(data?.queues || []);
    setSelectedWhatsapp(data?.whatsapp || []);
    setSelectedTags(data?.tags || []);
    setSelectedUsers(data?.users || []);
    setChoosePrefDate(data?.prefDate || null);
    setInitialDate(data?.initialDate || new Date());
    setFinalDate(data?.finalDate || new Date());
  }, [getSavedFilters]);

  useEffect(() => {
    if (tab !== 'search') setSearchParam('');
  }, [tab]);

  useEffect(() => {
    getWhatsappsByQueues();
  }, []);

  useEffect(() => {
    if (user.profile?.toLowerCase() === 'admin' || user.showChat) {
      setShowAllTickets(true);
    }
  }, [user.profile, setShowAllTickets]);

  return (
    <Paper elevation={0} variant="outlined" className={clsx(classes.root, classes.rootContainer)}>
      <FunnerModal
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        setPrefObject={setPrefObject}
        prefObject={prefObject}
        selectedTab={selectedTab}
      />

      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={ticket => {
          handleCloseOrOpenTicket(ticket);
        }}
      />

      <FilterModal
        modalOpen={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        tab={tab}
        selectedQueues={selectedQueues}
        setSelectedQueues={setSelectedQueues}
        selectedWhatsapp={selectedWhatsapp}
        setSelectedWhatsapp={setSelectedWhatsapp}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        userWhatsapps={userWhatsapps}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        choosePrefDate={choosePrefDate}
        initialDate={initialDate}
        finalDate={finalDate}
        setChoosePrefDate={setChoosePrefDate}
        setInitialDate={setInitialDate}
        setFinalDate={setFinalDate}
      />

      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
          className={classes.tabsContainer}
          classes={{ indicator: 'indicator' }}
        >
          <Tab value={'open'} icon={<Inbox />} className={classes.tab} classes={{ selected: 'selected' }} />
          <Tab value={'group'} icon={<Users />} className={classes.tab} classes={{ selected: 'selected' }} />
          <Tab value={'search'} icon={<Search />} className={classes.tab} classes={{ selected: 'selected' }} />
        </Tabs>
      </Paper>

      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        {tab === 'search' ? (
          <>
            <FormControlLabel
              sx={{ color: '#aaa' }}
              label={i18n.t('tickets.tabs.closed.title')}
              value={searchClosed}
              onChange={() => setSearchClosed(prev => !prev)}
              control={
                <Checkbox
                  sx={{
                    color: '#aaa',
                    '&.Mui-checked': {
                      color: '#0032C0',
                    },
                  }}
                />
              }
            />

            <IconButton
              classes={{ root: 'iconButton' }}
              variant="outlined"
              color="primary"
              onClick={() => setFilterModalOpen(true)}
              title="Filter"
            >
              <Badge
                badgeContent={filtersCount}
                color="secondary"
                classes={{ badge: classes.filterBadge }}
              >
                <Filter color="#aaa" strokeWidth="1.5px" size={24} />
              </Badge>
            </IconButton>
          </>
        ) : (
          <div className={classes.filterContainer}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 6,
                cursor: 'pointer',
              }}
            >
              <IconButton
                classes={{ root: 'iconButton' }}
                variant="outlined"
                color="primary"
                onClick={() => setNewTicketModalOpen(true)}
              >
                <MessageCirclePlus strokeWidth="1.5px" size={24} color="#00d128" />
              </IconButton>
            </div>

            <div className={classes.filterIcons}>
              <IconButton
                classes={{ root: 'iconButton' }}
                variant="outlined"
                color="primary"
                onClick={() => setUnreadMessages(!unreadMessages)}
                title="Não lidas"
                style={{
                  marginLeft: '10px',
                }}
              >
                <BellRing
                  strokeWidth="1.5px"
                  size={24}
                  style={{
                    color: unreadMessages ? 'blue' : '#aaa',
                  }}
                />
              </IconButton>
              <div className={classes.filterSort} onClick={handleFunner}>
                <ArrowDownNarrowWide strokeWidth="1.5px" size={24} />
              </div>

              <IconButton
                classes={{ root: 'iconButton' }}
                variant="outlined"
                color="primary"
                onClick={() => setFilterModalOpen(true)}
                title="Filter"
              >
                <Badge
                  badgeContent={filtersCount}
                  color="secondary"
                  classes={{ badge: classes.filterBadge }}
                >
                  <Filter color="#aaa" strokeWidth="1.5px" size={24} />
                </Badge>
              </IconButton>
            </div>
          </div>
        )}
      </Paper>

      <TabPanel value={tab} name="open" className={classes.root}>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className={classes.ticketsOptionContainer}
          classes={{ indicator: 'indicator' }}
        >
          <Tab
            label={
              <Badge className={classes.badge} badgeContent={openCount} color="secondary">
                {i18n.t('ticketsList.assignedHeader')}
              </Badge>
            }
            value={'open'}
            className={classes.ticketOption}
            classes={{ selected: 'selected' }}
          />
          <Tab
            label={
              <Badge className={classes.badge} badgeContent={pendingCount} color="secondary">
                {i18n.t('ticketsList.pendingHeader')}
              </Badge>
            }
            value={'pending'}
            className={classes.ticketOption}
            classes={{ selected: 'selected' }}
          />
        </Tabs>
        <Paper elevation={0} square className={classes.root}>
          <TicketsList
            tab={tab}
            status="open"
            showAll={showAllTickets}
            tags={selectedTags}
            selectedQueues={selectedQueues}
            selectedWhatsapp={selectedWhatsapp}
            unreadMessages={unreadMessages}
            updateCount={val => setOpenCount(val)}
            users={selectedUsers}
            style={applyPanelStyle('open')}
            order={prefObject['open']}
            choosePrefDate={choosePrefDate}
            initialDate={initialDate}
            finalDate={finalDate}
          />

          <TicketsList
            tab={tab}
            status="pending"
            showAll={showAllTickets}
            unreadMessages={unreadMessages}
            tags={selectedTags}
            users={selectedUsers}
            selectedQueues={selectedQueues}
            selectedWhatsapp={selectedWhatsapp}
            updateCount={val => setPendingCount(val)}
            style={applyPanelStyle('pending')}
            order={prefObject['pending']}
            choosePrefDate={choosePrefDate}
            initialDate={initialDate}
            finalDate={finalDate}
          />
        </Paper>
      </TabPanel>

      <TabPanel value={tab} name="group" className={classes.root}>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className={classes.ticketsOptionContainer}
          classes={{ indicator: 'indicator' }}
        >
          <Tab
            label={
              <Badge className={classes.badge} badgeContent={openCount} color="secondary">
                {i18n.t('ticketsList.assignedHeader')}
              </Badge>
            }
            value={'open'}
            className={classes.ticketOption}
            classes={{ selected: 'selected' }}
          />
          <Tab
            label={
              <Badge className={classes.badge} badgeContent={pendingCount} color="secondary">
                {i18n.t('ticketsList.pendingHeader')}
              </Badge>
            }
            value={'pending'}
            className={classes.ticketOption}
            classes={{ selected: 'selected' }}
          />
        </Tabs>

        <Paper elevation={0} className={classes.root}>
          <TicketsList
            tab={tab}
            status="open"
            isGroup
            showAll={showAllTickets}
            unreadMessages={unreadMessages}
            tags={selectedTags}
            selectedQueues={selectedQueues}
            users={selectedUsers}
            selectedWhatsapp={selectedWhatsapp}
            updateCount={val => setOpenCount(val)}
            style={applyPanelStyle('open')}
            order={prefObject['open']}
            choosePrefDate={choosePrefDate}
            initialDate={initialDate}
            finalDate={finalDate}
          />

          <TicketsList
            tab={tab}
            status="pending"
            isGroup
            showAll={showAllTickets}
            unreadMessages={unreadMessages}
            users={selectedUsers}
            tags={selectedTags}
            selectedQueues={selectedQueues}
            selectedWhatsapp={selectedWhatsapp}
            updateCount={val => setPendingCount(val)}
            style={applyPanelStyle('pending')}
            order={prefObject['pending']}
            choosePrefDate={choosePrefDate}
            initialDate={initialDate}
            finalDate={finalDate}
          />
        </Paper>
      </TabPanel>

      <TabPanel
        value={tab}
        name="search"
        className={classes.root}
      >
        <div className={classes.searchInputWrapper}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.searchInput}
            placeholder={i18n.t('tickets.search.placeholder')}
            type="search"
            value={searchParam}
            onChange={handleSearch}
          />
        </div>

        <TicketsList
          tab={tab}
          searchParam={searchParam}
          status={searchClosed ? 'closed' : undefined}
          showAll={true}
          tags={selectedTags}
          users={selectedUsers}
          selectedQueues={selectedQueues}
          selectedWhatsapp={selectedWhatsapp}
					choosePrefDate={choosePrefDate}
					initialDate={initialDate}
					finalDate={finalDate}
        />
      </TabPanel>
    </Paper>
  );
}

export default TicketsManagerTabs;
