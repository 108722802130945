import React, { Fragment, useCallback, useEffect, useReducer, useRef, useState } from 'react';

import { Avatar, Button, CircularProgress, Divider, IconButton, Typography } from '@material-ui/core';
import { AccessTime, Block, Done, DoneAll, ExpandMore, GetApp } from '@material-ui/icons';
import clsx from 'clsx';
import { format, isSameDay, parseISO } from 'date-fns';

import toastError from '../../errors/toastError';
import EmailMessage from '../../libs/HubEcosystem/components/EmailMessage';
import api from '../../services/api';
import { socketConnection } from '../../services/socket';
import Loading from '../Loading';
import MarkdownWrapper from '../MarkdownWrapper';
import MessageOptionsMenu from '../MessageOptionsMenu';
import ModalImageCors from '../ModalImageCors';
import QuotedMessage from './components/QuotedMessage';
import ViewOnce from './components/ViewOnce';
import { checkBodyIsMediaUrl } from './helpers';
import { useStyles } from './styles';

const reducer = (state, action) => {
  if (action.type === 'LOAD_MESSAGES') {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach(message => {
      const messageIndex = state.findIndex(m => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === 'ADD_MESSAGE') {
    const newMessage = action.payload;
    const messageIndex = state.findIndex(m => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === 'UPDATE_MESSAGE') {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex(m => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === 'DELETE_MESSAGE') {
    const { id } = action.payload;
    const filter = state.filter(m => Number(m.id) !== Number(id));
    return [...filter];
  }

  if (action.type === 'RESET') {
    return [];
  }
};

function MessagesList({ ticket }) {
  const classes = useStyles();
  const lastMessageRef = useRef();
  const currentTicketId = useRef(ticket.id);
  const [messagesList, dispatch] = useReducer(reducer, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const messageOptionsMenuOpen = Boolean(anchorEl);

  const loadMore = () => {
    setLoading(true);
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = e => {
    if (!hasMore) return;

    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById('messagesList').scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = e => {
    setAnchorEl(null);
  };

  const renderMessageAck = message => {
    const whiteStyle = { color: '#fff' };

    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} style={whiteStyle} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} style={whiteStyle} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} style={whiteStyle} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} style={whiteStyle} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), 'dd/MM/yyyy')}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), 'dd/MM/yyyy')}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return <div key={`ref-${message.createdAt}`} ref={lastMessageRef} style={{ float: 'left', clear: 'both' }} />;
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>;
      }
    }
  };

  const vCard = useCallback(message => {
    const name = message?.substring(message.indexOf('N:;') + 3, message.indexOf(';;;'));
    const description = message?.substring(message.indexOf('TION:') + 5, message.indexOf('TEL'));
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
          oiii
          {/* <Avatar style={{ marginRight: 10, marginLeft: 20, width: 60, height: 60 }} /> */}
          <Avatar src={ticket?.contact.profilePicUrl} alt="contact_image" style={{ width: 40, height: 40 }} />
          <div style={{ width: 350 }}>
            <div>
              <Typography noWrap component="h4" variant="body2" color="textPrimary" style={{ fontWeight: '700' }}>
                {name}
              </Typography>
            </div>

            <div style={{ width: 350 }}>
              <Typography component="span" variant="body2" color="textPrimary" style={{ display: 'flex' }}>
                {description}
              </Typography>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            borderWidth: '1px 0 0 0',
            borderTopColor: '#bdbdbd',
            borderStyle: 'solid',
            padding: 8,
          }}
        >
          <Typography
            noWrap
            component="h4"
            variant="body2"
            color="textPrimary"
            style={{ fontWeight: '700', color: '#2c9ce7' }}
          >
            Conversar
          </Typography>
        </div>
      </div>
    );
  }, []);

  const messageLocation = useCallback((message, createdAt) => {
    return (
      <div className={[classes.textContentItem, { display: 'flex', padding: 5 }]}>
        <img src={message.split('|')[0]} className={classes.imageLocation} />
        <a style={{ fontWeight: '700', color: '#9310fb' }} target="_blank" href={message.split('|')[1]}>
          {' '}
          Clique para ver localização
        </a>
        <span className={classes.timestamp}>{format(parseISO(createdAt), 'HH:mm')}</span>
      </div>
    );
  }, []);

  const checkMessageMedia = message => {
    if (message.mediaType === 'image') {
      return <ModalImageCors imageUrl={message.mediaUrl} />;
    }

    if (message.mediaType === 'audio') {
      return (
        <audio controls>
          <source src={message.mediaUrl} type="audio/ogg" />
        </audio>
      );
    }

    if (message.mediaType === 'video') {
      return <video className={classes.videoMedia} src={message.mediaUrl} controls />;
    }

    return (
      <>
        <div className={classes.downloadMedia}>
          <Button startIcon={<GetApp />} color="primary" variant="outlined" target="_blank" href={message.mediaUrl}>
            Download
          </Button>
        </div>
        {!checkBodyIsMediaUrl(message) && <Divider />}
      </>
    );
  };

  const switchMessageType = useCallback((message, channel) => {
    const isFromMe = message.fromMe;
    const isEmail = channel === 'email';
    const messageBody = isEmail
      ? <EmailMessage message={message.body} />
      : <MarkdownWrapper>{message.body}</MarkdownWrapper>;

    const MessageComponent = isFromMe ? Fragment : ({ children }) => (
      <div
        className={clsx({
          [classes.textContent]: isEmail,
          [classes.textContentItem]: !checkBodyIsMediaUrl(message) && !isEmail},
        )}
      >
        {children}
      </div>
    );

    if (message.body.includes('data:image')) {
      return messageLocation(message.body, message.createdAt);
    }

    if (message.body.includes('BEGIN:VCARD')) {
      return (
        <div className={[classes.textContentItem, { marginRight: 0 }]}>
          {vCard(message.body)}
        </div>
      );
    }

    return (
      <MessageComponent>
        { message.quotedMsg && (
          <QuotedMessage quotedMsg={message.quotedMsg} isFromMe={message.fromMe}/>
        )}
        {
          !checkBodyIsMediaUrl(message) && messageBody
        }
        <span
          className={clsx(classes.timestamp, { [classes.timestampFromMe]: isFromMe })}
        >
          {format(parseISO(message.createdAt), 'HH:mm')}
          {isFromMe && renderMessageAck(message)}
        </span>
      </MessageComponent>
    )
  }, [messageLocation, vCard]);

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        if (message.mediaType === 'comment') {
          return (
            <div className={classes.comment}>
              <i>{`${message.user?.name || 'Indefinido'}: ${message.note}`}</i>
            </div>
          );
        }

        if (!message.fromMe) {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}

              <div className={classes.messageAvatarWrapper}>
                <Avatar
                  src={ticket?.contact.profilePicUrl}
                  alt="contact_image"
                  style={{ width: 30, height: 30, marginRight: 5 }}
                />

                <div
                  id={message.id}
                  className={clsx(
                    classes.message,
                    classes.messageLeft,
                    { [classes.imageMediaContainer]: message.mediaType === 'image' },
                    { [classes.mediaContainer]: checkBodyIsMediaUrl(message) },
                    { [classes.audioContainer]: message.mediaType === 'audio' },
                  )}
                  title={message.queueId && message.queue?.name}
                >
                  <IconButton
                    variant="contained"
                    size="small"
                    id="messageActionsButton"
                    disabled={message.isDeleted}
                    className={classes.messageActionsButton}
                    onClick={e => handleOpenMessageOptionsMenu(e, message)}
                  >
                    <ExpandMore />
                  </IconButton>

                  {
                    ticket?.isGroup && (
                      <span className={classes.messageContactName}>
                        {message.contact?.name}
                      </span>
                    )
                  }

                  {message.mediaUrl && checkMessageMedia(message)}

                  {message.mediaType === 'viewOnceMessageV2' && <ViewOnce />}

                  {switchMessageType(message, ticket.channel)}
                </div>
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}

              <div
                id={message.id}
                className={clsx(
                  classes.message,
                  classes.messageRight,
                  { [classes.imageMediaContainer]: message.mediaType === 'image' },
                  { [classes.mediaContainer]: checkBodyIsMediaUrl(message) },
                  { [classes.audioContainer]: message.mediaType === 'audio' },
                )}
                title={message.queueId && message.queue?.name}
              >
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={e => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>

                {message.mediaUrl && checkMessageMedia(message)}

                <div
                  className={clsx({
                    [classes.textContentItem]: !checkBodyIsMediaUrl(message),
                    [classes.textContentItemDeleted]: message.isDeleted,
                  })}
                >
                  {message.isDeleted && <Block color="disabled" fontSize="small" className={classes.deletedIcon} />}

                  {switchMessageType(message, ticket.channel)}
                </div>
              </div>
            </React.Fragment>
          );
        }
      });
      return (
        <>
          {loading && <Loading />}
          {viewMessagesList}
        </>
      );
    } else {
      return <div>Say hello to your new contact!</div>;
    }
  };

  useEffect(() => {
    dispatch({ type: 'RESET' });
    setPageNumber(1);

    currentTicketId.current = ticket.id;
  }, [ticket.id]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        if (ticket.id === undefined) return;
        try {
          const { data } = await api.get('/messages/' + ticket.id, {
            params: { pageNumber },
          });

          if (currentTicketId.current === ticket.id) {
            const messages = [...data.messages, ...data.notes.map(note => ({ mediaType: 'comment', ...note }))].sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
            dispatch({ type: 'LOAD_MESSAGES', payload: messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && data.messages.length > 1) {
            scrollToBottom();
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchMessages();
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticket.id]);

  useEffect(() => {
    const companyId = localStorage.getItem('companyId');
    const socket = socketConnection({ companyId });

    socket.on('connect', () => socket.emit('joinChatBox', `${ticket.id}`));

    socket.on(`company-${companyId}-appMessage`, data => {
      if (data.action === 'create') {
        dispatch({ type: 'ADD_MESSAGE', payload: data.message });
        scrollToBottom();
      }

      if (data.action === 'update') {
        dispatch({ type: 'UPDATE_MESSAGE', payload: data.message });
      }
    });

    socket.on(`company-${companyId}-ticketNotes`, data => {
      if (data.action === 'create') {
        dispatch({ type: 'ADD_MESSAGE', payload: { ...data.ticketNote, mediaType: 'comment' } });
      }

      if (data.action === 'delete') {
        dispatch({ type: 'DELETE_MESSAGE', payload: { id: data.ticketNoteId } });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticket]);

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
      />

      <div id="messagesList" className={classes.messagesList} onScroll={handleScroll}>
        {
          messagesList.length > 0 ? renderMessages()
            : loading && <CircularProgress className={classes.circleLoading} />
        }
      </div>

      {ticket?.channel !== 'whatsapp' && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            padding: '10px',
            alignItems: 'center',
            backgroundColor: '#E1F3FB',
          }}
        >
          {/*{ticket?.channel === "facebook" ? (
            <Facebook small />
          ) : (
            <Instagram small />
          )}

          <span>
            Você tem 24h para responder após receber uma mensagem, de acordo
            com as políticas do Facebook.
          </span>*/}
        </div>
      )}
    </div>
  );
}

export default MessagesList;
