import React, { useContext, useEffect, useState } from "react";

import { FormControlLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { AuthContext } from "../../context/Auth";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { Can } from "../Can";
import QueueSelect from "../QueueSelect";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	controlLabel: {
		marginLeft: 0,
		marginBottom: theme.spacing(1),
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email").required("Required"),
});

const UserModal = ({ open, onClose, userId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		email: "",
		password: "",
		profile: "user",
		routerTimer: "",
		routerWeight: "",
	};

	const { user: loggedInUser } = useContext(AuthContext);

	const [user, setUser] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [showChat, setShowChat] = useState(false);
	const [showClosedTickets, setShowClosedTickets] = useState(false);
	const [showChatHistory, setShowChatHistory] = useState(false);

	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				setShowChat(data.showChat);
				setShowClosedTickets(data.showClosedTickets);
				setShowChatHistory(data.showChatHistory);
				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
	};

	const handleSaveUser = async values => {
		const userData = {
			...values,
			queueIds: selectedQueueIds,
			showChat,
			showClosedTickets,
			showChatHistory,
		};
		try {
			if (userId) {
				await api.put(`/users/${userId}`, userData);
			} else {
				await api.post("/users", userData);
			}
			toast.success(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `${i18n.t("userModal.title.edit")}`
						: `${i18n.t("userModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										fullWidth
									/>

									<Field
										as={TextField}
										label={i18n.t("userModal.form.password")}
										type="password"
										name="password"
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>

								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										variant="outlined"
										margin="dense"
										fullWidth
									/>

									<FormControl
										variant="outlined"
										className={classes.formControl}
										margin="dense"
									>
										<Can
											role={loggedInUser.profile}
											perform="user-modal:editProfile"
											yes={() => (
												<>
													<InputLabel id="profile-selection-input-label">
														{i18n.t("userModal.form.profile")}
													</InputLabel>

													<Field
														as={Select}
														label={i18n.t("userModal.form.profile")}
														name="profile"
														labelId="profile-selection-label"
														id="profile-selection"
														required
													>
														<MenuItem value="admin">Admin</MenuItem>
														<MenuItem value="user">User</MenuItem>
													</Field>
												</>
											)}
										/>
									</FormControl>
								</div>

								<Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (
										<>
											<QueueSelect
												selectedQueueIds={selectedQueueIds}
												onChange={values => setSelectedQueueIds(values)}
											/>

											{user?.profile === "user" && (
												<>
													<FormControl
														variant="outlined"
														className={classes.formControl}
														margin="dense"
														style={{ width: '100%', margin: '12px 0' }}
													>
														<InputLabel id="router-timer">
															Tempo de Rodízio
														</InputLabel>

														<Field
															as={Select}
															labelId="router-timer"
															label="Tempo de Rodízio"
															name="routerTimer"
															error={touched.routerTimer && Boolean(errors.routerTimer)}
															helperText={touched.routerTimer && errors.routerTimer}
															variant="outlined"
															margin="dense"
															className={classes.selectField}
														>
															<MenuItem value="0">Manter tempo do setor</MenuItem>
															<MenuItem value="2">2 minutos</MenuItem>
															<MenuItem value="5">5 minutos</MenuItem>
															<MenuItem value="10">10 minutos</MenuItem>
															<MenuItem value="15">15 minutos</MenuItem>
															<MenuItem value="30">30 minutos</MenuItem>
															<MenuItem value="45">45 minutos</MenuItem>
															<MenuItem value="60">60 minutos</MenuItem>
														</Field>
													</FormControl>

													<FormControl
														variant="outlined"
														className={classes.formControl}
														margin="dense"
														style={{ width: '100%', margin: '4px 0 0' }}
													>
														<InputLabel id="router-weight">
															Chances de obter primeiro ticket
														</InputLabel>

														<Field
															as={Select}
															labelId="router-weight"
															label="Chances de obter primeiro ticket"
															name="routerWeight"
															error={touched.routerWeight && Boolean(errors.routerWeight)}
															helperText={touched.routerWeight && errors.routerWeight}
															variant="outlined"
															margin="dense"
															className={classes.selectField}
														>
															<MenuItem value="1">Chance comum</MenuItem>
															<MenuItem value="2">2x mais chances</MenuItem>
															<MenuItem value="3">3x mais chances</MenuItem>
															<MenuItem value="4">4x mais chances</MenuItem>
															<MenuItem value="5">5x mais chances</MenuItem>
														</Field>
													</FormControl>
												</>
											)}
										</>
									)}
								/>

								<br />
								<br />

								{user?.profile === "user" && (
									<>
										<FormControlLabel
											label={"Ver conversas de outros usuários"}
											labelPlacement="end"
											className={classes.controlLabel}
											control={
												<Switch
													size="small"
													checked={showChat}
													onChange={(e) => {
														setShowChat(e.target.checked);
													}}
													color="primary"
												/>
											}
										/>
										<FormControlLabel
											label={"Ver tickets resolvidos"}
											labelPlacement="end"
											className={classes.controlLabel}
											control={
												<Switch
													size="small"
													checked={showClosedTickets}
													onChange={(e) => {
														setShowClosedTickets(e.target.checked);
													}}
													color="primary"
												/>
											}
										/>
										<FormControlLabel
											label={"Ver histórico da conversa"}
											labelPlacement="end"
											className={classes.controlLabel}
											control={
												<Switch
													size="small"
													checked={showChatHistory}
													onChange={(e) => {
														setShowChatHistory(e.target.checked);
													}}
													color="primary"
												/>
											}
										/>
									</>
								)}
							</DialogContent>

							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>

								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default UserModal;
