import React, { useContext, useEffect, useRef, useState } from "react";

import { Tooltip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import { grey } from '@material-ui/core/colors';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import AndroidIcon from '@material-ui/icons/Android';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { format, isSameDay, parseISO } from 'date-fns';
import { Pin } from 'lucide-react';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useAuthContext } from "../../context/Auth";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import { stringToColor } from "../../helpers/StringToColor";
import ChannelIcon from '../../libs/HubEcosystem/components/ChannelIcon';
import api from "../../services/api";
import ContactTag from "../ContactTag";
import MarkdownWrapper from "../MarkdownWrapper";
import TicketMessagesDialog from "../TicketMessagesDialog";
import { useStyles } from './styles';

function TicketListItemCustom(props) {
  const {
    ticket,
    isSelected,
    setSelectedTicketId,
    setPinnedTickets,
    isPinnedTicket,
    isTabOpen
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { setCurrentTicket } = useContext(TicketsContext);
  const { user } = useAuthContext();

  const [loading, setLoading] = useState(false);
  const [ticketUser, setTicketUser] = useState(null);
  const [whatsAppName, setWhatsAppName] = useState(null);
  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  const hasUnreadMessages = Boolean(ticket.unreadMessages);
  const tagsToShow = hasUnreadMessages ? 2 : 3;
  const tags = ticket.tags?.slice(0, tagsToShow) || [];
  const hasMoreTags = ticket.tags?.length > tagsToShow;
  const isUserProfile = user.profile === 'user' && !user.showChat;

  const itemClasses = clsx(classes.ticket, {
    [classes.selectedTicket]: isSelected,
  });

  const handleContextMenu = event => {
    event.preventDefault();
    if (ticket.status !== 'open' || !isTabOpen) return;

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handlePin = () => {
    const pinned = JSON.parse(localStorage.getItem('pinned'));

    handleCloseContextMenu();

    if (Array.isArray(pinned)) {
      if (pinned.includes(ticket.id)) {
        const newPinned = pinned.filter(ticketId => ticketId !== ticket.id);
        localStorage.setItem('pinned', JSON.stringify(newPinned));

        return setPinnedTickets(prev => {
          const newValue = prev.filter(tick => tick.id !== ticket.id);
          return newValue;
        });
      } else {
        pinned.push(ticket.id);
        localStorage.setItem('pinned', JSON.stringify(pinned));

        return setPinnedTickets(prev => [...prev, ticket]);
      }
    }

    localStorage.setItem('pinned', JSON.stringify([ticket.id]));
    return setPinnedTickets([ticket]);
  };

  const handleCloseTicket = async id => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: 'closed',
        justClose: true,
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }

    history.push(`/tickets/`);

    setLoading(false);
  };

  const handleAcepptTicket = async id => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: 'open',
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }

    history.push(`/tickets/${ticket.uuid}`);
    setLoading(false);
  };

  const handleSelectTicket = (ticket) => {
    const code = uuidv4();
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code });
    setSelectedTicketId(ticket.id);
  };

  const renderTicketInfo = () => {
    if (ticketUser) {
      return (
        <>
          <div className={classes.tooltipsWrapper}>
            {ticket.userId && (
              <Tooltip arrow placement="right" title={`Pertence ao usuário: ${ticket.user?.name}`}>
                <div
                  style={{ backgroundColor: stringToColor(ticket?.user?.name) }}
                  className={clsx(classes.ticketTooltipQueueColorAdmin, {
                    [classes.ticketTooltipQueueColorUser]: isUserProfile,
                  })}
                >
                  {isUserProfile ? null : (
                    <Typography
                      color="white"
                      variant="caption"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      maxWidth="12ch"
                      display="block"
                    >
                      {ticket.user?.name.split(' ')[0]}
                    </Typography>
                  )}
                </div>
              </Tooltip>
            )}

            {ticket.chatbot && (
              <Tooltip title="Chatbot">
                <AndroidIcon fontSize="small" style={{ color: grey[700], marginRight: 5 }} />
              </Tooltip>
            )}

            <Stack direction="row" alignItems="center" marginLeft="8px" maxWidth="90%" gap={1}>
              {tags.map(tag => {
                return <ContactTag tag={tag} key={tag.id} />;
              })}

              {hasMoreTags ? '[...]' : null}
            </Stack>
          </div>
        </>
      );
    } else {
      return (
        <>
          {ticket.queue?.name !== null && (
            <Tooltip arrow placement="right" title={ticket.queue?.name || 'Sem fila'}>
              <span
                style={{ backgroundColor: ticket.queue?.color || '#7C7C7C', top: -80 }}
                className={classes.ticketTooltipQueueColor}
              />
            </Tooltip>
          )}

          {ticket.chatbot && (
            <Tooltip title="Chatbot">
              <AndroidIcon fontSize="small" style={{ color: grey[700], marginRight: 5 }} />
            </Tooltip>
          )}
        </>
      );
    }
  };

  useEffect(() => {
    if (ticket.userId && ticket.user) {
      setTicketUser(ticket.user.name);
    }

    if (ticket.whatsappId && ticket.whatsapp) {
      setWhatsAppName(ticket.whatsapp.name);
    }

    return () => {
      isMounted.current = false;
    };
  }, [ticket]);

  useEffect(() => {
    // Chama a função para deselecionar o ticket aqui
    // Se você estiver usando um estado para controlar o ticket selecionado, você pode resetá-lo aqui
    setSelectedTicketId(null); // Assumindo que `setSelectedTicketId` é a função para atualizar o estado do ticket selecionado

    // Outras inicializações podem ser feitas aqui
  }, []);

  return (
    <React.Fragment key={ticket.id}>
      <TicketMessagesDialog
        open={openTicketMessageDialog}
        handleClose={() => setOpenTicketMessageDialog(false)}
        ticketId={ticket.id}
      />

      <ListItem
        onContextMenu={handleContextMenu}
        dense
        button
        onClick={e => handleSelectTicket(ticket)}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === 'pending',
          [classes.selectedTicket]: isSelected,
          [classes.ticketUnread]: ticket.unreadMessages || ticket.markedUnreadMessages,
        })}
      >
        {isPinnedTicket && (
          <Pin
            strokeWidth="1.5px"
            size={24}
            style={{
              position: 'absolute',
              right: 0,
              top: '-8px',
            }}
          />
        )}

        <Tooltip arrow placement="right" title={ticket.queue?.name || 'Sem fila'}>
          <span style={{ backgroundColor: ticket.queue?.color || '#7C7C7C' }} className={classes.ticketQueueColor} />
        </Tooltip>

        <ListItemAvatar>
          <Avatar src={ticket?.contact?.profilePicUrl} style={{ marginLeft: '10px', width: '70px', height: '70px' }} />
        </ListItemAvatar>

        <div className={classes.ticketChannelIconWrapper}>
          <Tooltip title={`Conexão ${whatsAppName}`}>
            <ChannelIcon channel={ticket.channel} />
          </Tooltip>
        </div>

        <ListItemText
          disableTypography
          className={classes.contactWrapper}
          primary={
            <Tooltip placement="right" title={ticket.contact.name}>
              <Stack>
                <Typography noWrap component="span" variant="body2" color="textPrimary" className={classes.titleName}>
                  {ticket.contact.name}
                </Typography>
              </Stack>
            </Tooltip>
          }
          secondary={
            <div className={classes.contactInfoWrapper}>
              <Tooltip placement="right" title={ticket.lastMessage}>
                <Typography
                  className={classes.contactLastMessage}
                  noWrap
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {ticket.lastMessage.includes('data:image/png;base64') ? (
                    <MarkdownWrapper>Localização</MarkdownWrapper>
                    ) : (
                    <Typography>{ticket.lastMessage}</Typography>
                  )}
                </Typography>
              </Tooltip>

              <Box className={classes.ticketInfo1}>{renderTicketInfo()}</Box>
            </div>
          }
        />

        <div className={classes.statusContainer}>
          {ticket.lastMessage && (
            <>
              <Typography className={classes.lastMessageTime} component="span" variant="body2" color="textSecondary">
                {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                  <>{format(parseISO(ticket.updatedAt), 'HH:mm')}</>
                ) : (
                  <>{format(parseISO(ticket.updatedAt), 'dd/MM/yyyy')}</>
                )}
              </Typography>

              {ticket.markedUnreadMessages ? (
                <>
                  <p>🟢</p>
                </>
              ) : (
                <Badge
                  badgeContent={ticket.unreadMessages ? ticket.unreadMessages : null}
                  classes={{
                    badge: classes.badgeStyle,
                  }}
                />
              )}
            </>
          )}

          {ticket.status === 'closed' && <span className={classes.resolvedText}>RESOLVIDO</span>}
        </div>
      </ListItem>

      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem onClick={handlePin}>{isPinnedTicket ? 'Desafixar' : 'Fixar'}</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default TicketListItemCustom;
