import { useEffect, useState } from "react";

import toastError from "../../errors/toastError";
import api from "../../services/api";

const useTickets = ({
  searchParam,
  tags,
  users,
  pageNumber,
  status,
  date,
  updatedAt,
  showAll,
  queueIds,
	whatsappIds,
	order,
	unreadMessages,
	choosePrefDate,
	initialDate,
	finalDate,
  tab,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [pinnedTickets, setPinnedTickets] = useState([]);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTickets = async () => {
        try {
          const pinnedTicketIds = JSON.parse(localStorage.getItem('pinned'));

          if (Array.isArray(pinnedTicketIds) && tab === 'open' && status === 'open') {
            const pinned = await Promise.all(pinnedTicketIds.map(async (ticketId) => {
              const { data } = await api.get(`/tickets/${ticketId}`);
              return data;
            }));

            setPinnedTickets(pinned);
          }

          const { data } = await api.get("/tickets", {
            params: {
              searchParam,
              pageNumber,
              tags,
              users,
              status,
              date,
              updatedAt,
              showAll,
              queueIds,
							whatsappIds,
							order,
							unreadMessages,
							choosePrefDate,
							initialDate,
							finalDate
            },
          });

          setTickets(data.tickets);
          setHasMore(data.hasMore);
        } catch (err) {
          toastError(err);
        } finally {
          setLoading(false);
        }
      };
      fetchTickets();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [
    searchParam,
    tags,
    users,
    pageNumber,
    status,
    date,
    updatedAt,
    showAll,
    queueIds,
		whatsappIds,
		order,
		unreadMessages,
		choosePrefDate,
		initialDate,
		finalDate,
    tab,
  ]);

  return { tickets, pinnedTickets, setPinnedTickets, loading, hasMore };
};

export default useTickets;
