import React, { useState, useEffect, createContext, useContext } from "react";
import { useHistory } from "react-router-dom";

const TicketsContext = createContext();

export const useTicketsContext = () => {
	return useContext(TicketsContext);
}

const TicketsContextProvider = ({ children }) => {
	const [currentTicket, setCurrentTicket] = useState({ id: null, code: null });
	const [selectedTicketId, setSelectedTicketId] = useState(null);
	const [updateTicketTemp, setUpdateTicketTemp] = useState(null);

	const history = useHistory();

	const handleDeselectTicket = () => {
		setCurrentTicket({ id: null, code: null }); // Reset currentTicket
		setSelectedTicketId(null); // Reseta o ticket selecionado
		console.log("Ticket deselecionado");
		console.log("handleDeselectTicket");
	};
	useEffect(() => {
			if (currentTicket.id !== null) {
					history.push(`/tickets/${currentTicket.uuid}`);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTicket])

	const emitUpdateTicket = (ticket) => {
		setUpdateTicketTemp(ticket);

		setTimeout(() => {
			setUpdateTicketTemp(null);
		}, 2000);
	};

	return (
		<TicketsContext.Provider
			value={{ currentTicket, setCurrentTicket, handleDeselectTicket, updateTicketTemp, emitUpdateTicket }}
		>
			{children}
		</TicketsContext.Provider>
	);
};

export { TicketsContext, TicketsContextProvider };
