import React, { useEffect, useRef, useState } from "react";

import {
	Box,
	Button,
	FormControl,
	IconButton,
	Input,
	InputAdornment,
	makeStyles,
	Paper,
	Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFile from "@material-ui/icons/AttachFile";
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";

import ModalImageCors from "../../components/ModalImageCors";
import { useAuthContext } from "../../context/Auth";
import { useDate } from "../../hooks/useDate";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "hidden",
    borderRadius: 0,
    height: "100%",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: '#f2f2f2',
  },

  messageList: {
    position: "relative",
    overflowY: "auto",
    height: "100%",
    ...theme.scrollbarStyles,
    // background: theme.palette.total,
		backgroundColor: "#f2f2f2"
  },

  inputArea: {
    position: "relative",
    height: "auto",
		borderTop: "3px solid black",
  },

  input: {
    padding: "20px",
  },

	viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.total,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

	uploadInput: {
		display: "none",
	},

  buttonSend: {
    margin: theme.spacing(1),
  },

  boxLeft: {
    padding: "10px 10px 5px",
    margin: "10px",
    position: "relative",
    backgroundColor: "yellow",
    color: "black",
    maxWidth: 300,
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },

  boxRight: {
    padding: "10px 10px 5px",
    margin: "10px 10px 10px auto",
    position: "relative",
    backgroundColor: "#08c9ff",
    color: "black",
    textAlign: "right",
    maxWidth: 300,
    borderRadius: 10,
    borderBottomRightRadius: 0,
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));

export default function ChatMessages({
  chat,
  messages,
  handleSendMessage,
  handleLoadMore,
  scrollToBottomRef,
  pageInfo,
  loading,
}) {
  const classes = useStyles();
  const { datetimeToClient } = useDate();
  const baseRef = useRef();
  const { user } = useAuthContext();

	const [media, setMedia] = useState(null);
  const [contentMessage, setContentMessage] = useState("");

  const scrollToBottom = () => {
    if (baseRef.current) {
      baseRef.current.scrollIntoView({});
    }
  };

	const handleChangeMedias = (e) => {
		if (!e.target.files) return;

		const file = e.target.files[0];
		setMedia(file);
	};

  const unreadMessages = (chat) => {
    if (chat !== undefined) {
      const currentUser = chat.users.find((u) => u.userId === user.id);
      return currentUser.unreads > 0;
    }
    return 0;
  };

  const handleScroll = (e) => {
    const { scrollTop } = e.currentTarget;
    if (!pageInfo.hasMore || loading) return;
    if (scrollTop < 600) {
      handleLoadMore();
    }
  };

	const parserRenderContent = (item) => {
		if (item.mediaType.includes('image')) {
			return (
				<ModalImageCors
					imageUrl={item.mediaPath}
				/>
			)
		} else if (item.mediaType.includes('mp4')) {
			return (
				<video width="270px" controls>
					<source
						src={item.mediaPath}
						type={item.mediaType}
					/>
				</video>
			)
		} else if (item.mediaType.includes('audio')) {
			return (
				<audio controls>
					<source
						src={item.mediaPath}
						type={item.mediaType}
					/>
				</audio>
			)
		} else if (item.mediaType.includes('text')) {
			return item.message;
		} else {
			return (
				<a
					href={item.mediaPath}
					target="_blank"
					rel="noreferrer"
				>
					<Button
						variant="contained"
						color="secondary"
						size="medium"
					>
						ARQUIVO
					</Button>
				</a>
			)
		}
	};

	const handleSendMessageMedia = async () => {
		await handleSendMessage(contentMessage, media);
		setContentMessage("");
		setMedia(null);
		scrollToBottom()
	};

	useEffect(() => {
    if (unreadMessages(chat) > 0) {
      try {
        api.post(`/chats/${chat.id}/read`, { userId: user.id });
      } catch (err) {}
    }
    scrollToBottomRef.current = scrollToBottom;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className={classes.mainContainer}>
      <div onScroll={handleScroll} className={classes.messageList}>
        {Array.isArray(messages) &&
          messages.map((item, key) => {
            if (item.senderId === user.id) {
              return (
								<>
									<Box key={key} className={classes.boxRight}>
										<Typography variant="subtitle2">
											{item.sender.name}
										</Typography>
										{parserRenderContent(item)}
										<Typography variant="caption" display="block">
											{datetimeToClient(item.createdAt)}
										</Typography>
									</Box>
									<div ref={baseRef}></div>
								</>
              );
            } else {
              return (
								<>
									<Box key={key} className={classes.boxLeft}>
										<Typography variant="subtitle2">
											{item.sender.name}
										</Typography>
										{parserRenderContent(item)}
										<Typography variant="caption" display="block">
											{datetimeToClient(item.createdAt)}
										</Typography>
									</Box>
									<div ref={baseRef}></div>
								</>
              );
            }
          })}
      </div>
      <div className={classes.inputArea}>
				{media ? (
					<Paper elevation={0} square className={classes.viewMediaInputWrapper}>
						<IconButton
							aria-label="cancel-upload"
							component="span"
							onClick={(e) => setMedia(null)}
						>
							<CancelIcon className={classes.sendMessageIcons} />
						</IconButton>

						{loading ? (
							<div>
								<CircularProgress className={classes.circleLoading} />
							</div>
						) : (
							<span>
								{media?.name}
							</span>
						)}
						<IconButton
							aria-label="send-upload"
							component="span"
							onClick={handleSendMessageMedia}
							disabled={loading}
						>
							<SendIcon className={classes.buttonSend} />
						</IconButton>
					</Paper>
				) : (
					<FormControl variant="outlined" fullWidth>
						<Input
							multiline
							value={contentMessage}
							placeholder="Digite sua mensagem"
							onKeyUp={(e) => {
								if (e.key === "Enter" && contentMessage.trim() !== "") {
									handleSendMessage(contentMessage);
									setContentMessage("");
								}
							}}
							onChange={(e) => setContentMessage(e.target.value)}
							className={classes.input}
							endAdornment={
								<InputAdornment position="end">
									<input
										multiple
										type="file"
										id="upload-button"
										className={classes.uploadInput}
										onChange={handleChangeMedias}
									/>
									<label htmlFor="upload-button">
										<IconButton
											aria-label="upload"
											component="span"
										>
											<AttachFile />
										</IconButton>
									</label>
									<IconButton
										onClick={() => {
											if (contentMessage.trim() !== "") {
												handleSendMessage(contentMessage);
												setContentMessage("");
											}
										}}
										className={classes.buttonSend}
									>
										<SendIcon />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				)}
      </div>
    </Paper>
  );
}
