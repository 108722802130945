import React, { useEffect, useState } from "react";

import { Avatar, CardHeader, Tooltip } from "@material-ui/core";

import ChannelIcon from '../../libs/HubEcosystem/components/ChannelIcon';
import { i18n } from "../../translate/i18n";
import { useStyles } from './styles';

const TicketInfo = ({ contact, ticket, onClick }) => {
	const { user } = ticket
	const classes = useStyles();

	const [userName, setUserName] = useState('');
	const [contactName, setContactName] = useState('');

	const whatsappName = ticket.whatsapp.name;

	useEffect(() => {
		if (contact) {
			setContactName(contact.name);
			if(document.body.offsetWidth < 600) {
				if (contact.name.length > 10) {
					const truncadName = contact.name.substring(0, 10) + '...';
					setContactName(truncadName);
				}
			}
		}

		if (user && contact) {
			setUserName(`${i18n.t("messagesList.header.assignedTo")} ${user.name}`);

			if(document.body.offsetWidth < 600) {
				setUserName(`${user.name}`);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<CardHeader
				onClick={onClick}
				style={{ cursor: "pointer" }}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" style={{ width: "70px", height: "70px"}} />}
				title={`${contactName} #${ticket.id}`}
				subheader={ticket.user && `${userName}`}
			/>
			<div className={classes.ticketChannelIconWrapper}>
				<Tooltip title={`Conexão ${whatsappName}`}>
					<ChannelIcon channel={ticket.channel } />
				</Tooltip>
			</div>
		</>
	);
};

export default TicketInfo;
