import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  messageContactName: {
    display: 'flex',
    color: '#6bcbef',
    fontWeight: 500,
  },

  quotedContainer: {
    all:'unset',
    color: 'inherit',
    textDecoration: 'inherit',
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.9,
    },
  },

  quotedContainerLeft: {
    margin: '-3px -80px 6px -6px',
    overflow: 'hidden',
    backgroundColor: '#fff',
    borderRadius: '7.5px',
    display: 'flex',
    position: 'relative',
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: 'auto',
    display: 'block',
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
  },

  quotedSideColorLeft: {
    flex: 'none',
    width: '4px',
    backgroundColor: '#6bcbef',
  },

  quotedContainerRight: {
    margin: '-3px -80px 6px -6px',
    overflowY: 'hidden',
    backgroundColor: '#285aff',
    borderRadius: '7.5px',
    display: 'flex',
    position: 'relative',
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: 'auto',
    whiteSpace: 'pre-wrap',
  },

  quotedSideColorRight: {
    flex: 'none',
    width: '4px',
    backgroundColor: '#35cd96',
  },
}));
