import React, { useContext, useEffect, useReducer, useState } from 'react';

import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import { isArray } from 'lodash';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/ConfirmationModal';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import QuickMessageDialog from '../../components/QuickMessageDialog';
import TableRowSkeleton from '../../components/TableRowSkeleton';
import Title from '../../components/Title';
import { AuthContext } from '../../context/Auth';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { socketConnection } from '../../services/socket';
import { i18n } from '../../translate/i18n';

const reducer = (state, action) => {
  if (action.type === 'LOAD_QUICKMESSAGES') {
    //console.log("aqui");
    //console.log(action);
    //console.log(action.payload);
    const quickmessages = action.payload;
    const newQuickmessages = [];
    //console.log(newQuickmessages);

    if (isArray(quickmessages)) {
      quickmessages.forEach(quickemessage => {
        const quickemessageIndex = state.findIndex(u => u.id === quickemessage.id);
        if (quickemessageIndex !== -1) {
          state[quickemessageIndex] = quickemessage;
        } else {
          newQuickmessages.push(quickemessage);
        }
      });
    }

    return [...state, ...newQuickmessages];
  }

  if (action.type === 'UPDATE_QUICKMESSAGES') {
    const quickemessage = action.payload;
    const quickemessageIndex = state.findIndex(u => u.id === quickemessage.id);

    if (quickemessageIndex !== -1) {
      state[quickemessageIndex] = quickemessage;
      return [...state];
    } else {
      return [quickemessage, ...state];
    }
  }

  if (action.type === 'DELETE_QUICKMESSAGE') {
    const quickemessageId = action.payload;

    const quickemessageIndex = state.findIndex(u => u.id === quickemessageId);
    if (quickemessageIndex !== -1) {
      state.splice(quickemessageIndex, 1);
    }
    return [...state];
  }

  if (action.type === 'RESET') {
    return [];
  }
};

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: 'scroll',
    ...theme.scrollbarStyles,
  },
}));

const Quickemessages = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedQuickemessage, setSelectedQuickemessage] = useState(null);
  const [deletingQuickemessage, setDeletingQuickemessage] = useState(null);
  const [quickemessageModalOpen, setQuickMessageDialogOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState('');
  const [quickemessages, dispatch] = useReducer(reducer, []);
  const { user } = useContext(AuthContext);
  const { profile } = user;

  useEffect(() => {
    dispatch({ type: 'RESET' });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchQuickemessages();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem('companyId');
    const socket = socketConnection({ companyId });

    socket.on(`company-quickemessage`, data => {
      if (data.action === 'update' || data.action === 'create') {
        dispatch({ type: 'UPDATE_QUICKMESSAGES', payload: data.record });
      }
      if (data.action === 'delete') {
        dispatch({ type: 'DELETE_QUICKMESSAGE', payload: +data.id });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const fetchQuickemessages = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      //const searchParam = ({ companyId, userId: user.id });
      const { data } = await api.get('/quick-messages/list', {
        params: { companyId, userId: user.id },
      });
      //console.log(data);
      dispatch({ type: 'LOAD_QUICKMESSAGES', payload: data });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenQuickMessageDialog = () => {
    setSelectedQuickemessage(null);
    setQuickMessageDialogOpen(true);
  };

  const handleCloseQuickMessageDialog = () => {
    setSelectedQuickemessage(null);
    setQuickMessageDialogOpen(false);
    //window.location.reload();
    fetchQuickemessages();
  };

  const handleSearch = event => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditQuickemessage = quickemessage => {
    //console.log(quickemessage);
    setSelectedQuickemessage(quickemessage);
    setQuickMessageDialogOpen(true);
  };

  const handleDeleteQuickemessage = async quickemessageId => {
    try {
      await api.delete(`/quick-messages/${quickemessageId}`);
      toast.success(i18n.t('quickemessages.toasts.deleted'));
    } catch (err) {
      toastError(err);
    }
    setDeletingQuickemessage(null);
    setSearchParam('');
    setPageNumber(1);
    fetchQuickemessages();
    dispatch({ type: 'RESET' });
  };

  const loadMore = () => {
    setPageNumber(prevState => prevState + 1);
  };

  const handleScroll = e => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <div id="quick-messages-page">
      <MainContainer>
        <ConfirmationModal
          title={
            deletingQuickemessage &&
            `${i18n.t('quickemessages.confirmationModal.deleteTitle')} ${deletingQuickemessage.shortcode}?`
          }
          open={confirmModalOpen}
          onClose={setConfirmModalOpen}
          onConfirm={() => handleDeleteQuickemessage(deletingQuickemessage.id)}
        >
          {i18n.t('quickemessages.confirmationModal.deleteMessage')}
        </ConfirmationModal>
        <QuickMessageDialog
          resetPagination={() => {
            setPageNumber(1);
            fetchQuickemessages();
          }}
          open={quickemessageModalOpen}
          onClose={handleCloseQuickMessageDialog}
          aria-labelledby="form-dialog-title"
          quickemessageId={selectedQuickemessage && selectedQuickemessage.id}
        />
        <MainHeader>
          <Grid style={{ width: '99.6%' }} container>
            <Grid xs={12} sm={8} item>
              <Title>{i18n.t('quickemessages.title')}</Title>
            </Grid>
            <Grid xs={12} sm={4} item>
              <Grid spacing={2} container>
                <Grid xs={6} sm={6} item>
                  <TextField
                    fullWidth
                    placeholder={i18n.t('quickemessages.searchPlaceholder')}
                    type="search"
                    value={searchParam}
                    onChange={handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: 'gray' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid xs={6} sm={6} item>
                  <Button fullWidth variant="contained" onClick={handleOpenQuickMessageDialog} color="primary">
                    {i18n.t('quickemessages.buttons.add')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainHeader>
        <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">{i18n.t('quickemessages.table.shortcode')}</TableCell>

                <TableCell align="center">{i18n.t('quickemessages.table.mediaName')}</TableCell>
                <TableCell align="center">{i18n.t('quickemessages.table.status')}</TableCell>
                <TableCell align="center">{i18n.t('quickemessages.table.actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {quickemessages.map(quickemessage => (
                  <TableRow key={quickemessage.id}>
                    <TableCell align="center">{quickemessage.shortcode}</TableCell>

                    <TableCell align="center">{quickemessage.mediaName ?? 'Sem anexo'}</TableCell>
                    <TableCell align="center">
                      {quickemessage.geral === true ? <CheckCircleIcon style={{ color: 'green' }} /> : ''}
                    </TableCell>
                    <TableCell align="center">
                      {(profile === 'admin' ||
                        profile === 'supervisor' ||
                        (profile === 'user' && !quickemessage.geral)) && (
                        <IconButton size="small" onClick={() => handleEditQuickemessage(quickemessage)}>
                          <EditIcon />
                        </IconButton>
                      )}

                      {(profile === 'admin' ||
                        profile === 'supervisor' ||
                        (profile === 'user' && !quickemessage.geral)) && (
                        <IconButton
                          size="small"
                          onClick={e => {
                            setConfirmModalOpen(true);
                            setDeletingQuickemessage(quickemessage);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={5} />}
              </>
            </TableBody>
          </Table>
        </Paper>
      </MainContainer>
    </div>
  );
};

export default Quickemessages;
